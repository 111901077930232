import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../../layouts/Layout";
import { Container, Row, Col } from "react-bootstrap";
import CategoryTeaser from "../../components/CategoryTeaser";
import JoinTheProgram from "../../components/JoinTheProgram";
import TopList from "../../components/TopList";
import PostList from "../../components/PostList";

/**
 * Homepage Template
 * @param data - Graphql Queries
 * @param pageContext
 */
const IndexPage = ({ data, pageContext }) => {
  const page = data.page.edges[0].node;
  const categories = page?.acf?.home_categories;
  const startHereLink = page?.acf?.start_here_link?.path;
  const lastUpdated = data.lastUpdated.edges;
  const mostRead = data.top5?.edges;
  const featuredImage = page?.image?.url?.localFile?.childImageSharp?.fluid;
  const stringTranslations = pageContext.stringTranslations;
  const cookieTranslations = pageContext.cookieTranslations;

  return (
    <Layout
      context={pageContext}
      useContainer={false}
      className="homepage-page"
      showProgressbar={false}
      cookieTranslations={cookieTranslations}
    >
      <Container className="homepage-page__header">
        <Row>
          <Col
            lg={{ span: 5, offset: 1 }}
            className="d-flex flex-column justify-content-center align-items-start mb-4 mb-lg-0"
          >
            <p className="roofline" dangerouslySetInnerHTML={{__html: stringTranslations["Resource Center"]}} />
            <h1 dangerouslySetInnerHTML={{__html: page.post_title}} />

            {(page?.acf?.excerpt || page.post_content) && (
              <div
                className="intro"
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.excerpt || page.post_content
                }}
              />
            )}

            <Link to={startHereLink || data?.startHerePage?.path || '#'} style={{color: "inherit"}}>
              <button className="btn btn-primary btn--big">
                {stringTranslations["Start here"]}
              </button>
            </Link>

            <small>{ stringTranslations["Powered by Amazon Associates"] }</small>
          </Col>
          {featuredImage && (
            <Col lg={6}>
              <img
                srcSet={featuredImage.srcSet}
                src={featuredImage.src}
                alt={page.image.alt || page.post_title}
              />
            </Col>
          )}
        </Row>
      </Container>

      <div className="homepage-page__pillar-section">
        <Container>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              { categories && (
                <Row className="homepage-page__categories align-items-stretch ">
                  {categories.map(category => (
                    <Col
                      lg={6}
                      className="d-flex flex-column"
                      key={category.category.name}
                    >
                      <CategoryTeaser
                        posts={category.posts}
                        title={category.category.name}
                        path={category.category.path}
                        guide={category.pillars}
                        icon={
                          category?.category?.small_image?.localFile
                            ?.childImageSharp?.fixed
                        }
                      />
                    </Col>
                  ))}
                </Row>
              )}

              {data?.bountyPage?.path && (
                <Row>
                  <Col>
                    <JoinTheProgram
                      link={data?.bountyPage?.path}
                      title={ stringTranslations["Amazon Bounty Program"] }
                      benefit={ stringTranslations["Promoting Amazon services is just as easy as promoting products — just integrate an Associate Link or banner into your website."] }
                      btnText={ stringTranslations["More information"] }
                      variant="bounty"
                    />
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>

      <div className="homepage-page__posts-section">
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <PostList
                posts={lastUpdated}
                title={ stringTranslations["Last updated"] }
                withCategory={true}
              />
            </Col>
            {mostRead && mostRead.length > 0 && (
              <Col lg={{ span: 3, offset: 1 }}>
                <TopList items={mostRead} title={ stringTranslations["Most read"] } />
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query HomepageQuery($id: Int!, $languageCode: String!) {
    page: allWordpressPage(
      filter: {
        language: { language_code: { eq: $languageCode } }
        wordpress_id: { eq: $id }
      }
      limit: 1
    ) {
      edges {
        node {
          wordpress_id
          path
          post_content
          post_title
          image {
            alt
            url {
              localFile {
                childImageSharp {
                  fluid(jpegQuality: 100, maxWidth: 110) {
                    srcSet
                    src
                  }
                }
              }
            }
          }
          post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
          acf {
            excerpt
            start_here_link {
                path
            }
            home_categories {
              category {
                path
                name
                small_image {
                  localFile {
                    childImageSharp {
                      fixed(jpegQuality: 100, width: 110) {
                        srcSet
                        src
                      }
                    }
                  }
                }
              }
              posts {
                post_title
                path
              }
              pillars {
                post_title
                path
              }
            }
          }
        }
      }
    }
    lastUpdated: allWordpressPost(
      filter: { language: { language_code: { eq: $languageCode } } }
      sort: { fields: post_date, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
          ...PostTeaserFields
        }
      }
    }
    top5: allWordpressWpTop5(
      filter: { language: { language_code: { eq: $languageCode } } }
    ) {
      edges {
        node {
          path
          post_title
        }
      }
    }
    startHerePage: wordpressPage(template: {eq: "start-here-template"}, language: {language_code: {eq: $languageCode}}) {
        path
    }
    bountyPage: wordpressPage(template: {eq: "bounty-template"}, language: {language_code: {eq: $languageCode}}) {
        path
    }
  }
`;
