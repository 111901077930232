import React from "react";
import { Link } from "gatsby";

const CategoryTeaser = ({ title, icon, posts, path, guide }) => {
  return (
    <div className="category-teaser">
      <div className="category-teaser__header">
        <h2 className="category-teaser__headline">
          <Link to={path}>{title}</Link>
        </h2>
        {icon && (
          <img
            className="category-teaser__image"
            srcSet={icon.srcSet}
            src={icon.src}
            alt={title}
          />
        )}
      </div>
      <div className="category-teaser__content">
        {guide && guide.length > 0 && (
          <div className="category-teaser__guides">
            {guide.map(singleGuide => (
              <a
                className="category-teaser__guide"
                href={singleGuide.path}
                key={singleGuide.path}
              >
                <span className="icon icon-Star" />
                {singleGuide.post_title}
              </a>
            ))}
          </div>
        )}
        {posts && posts.length > 0 && (
          <ul className="category-teaser__list internal-links">
            {posts.map(post => (
              <li key={post.path}>
                <a href={post.path}>{post.post_title}</a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CategoryTeaser;
